
    import {computed, defineComponent, onMounted, reactive, toRefs,ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";
    import ApiBanner from "@/request/apis/api_banner";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";

    interface IState {
        searches:any,
        total:any,
        list:any,
        activeKey:any,
        visible:any,
        visible2:any,
        Form:any,
        editForm:any,
        fileList:any,
        loading:any,
        typeData:any,
        type:any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches:{
                    shop_name:'',
                    goods_name:'',
                    type:1,
                    platform_cate_id:'',
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize
                },
                type:'',
                typeData:[],
                total:0,
                fileList:[],
                list:[],
                activeKey:'',
                visible:false,
                visible2:false,
                loading:false,
                editForm: {
                    "id": '',
                    "name": '',
                    "pid": '',
                    "image": '',
                    "sort": '',
                    "code": '',
                    "is_show": '',
                    "bg_image": '',
                    "level": '',
                    "remark": ''
                },
                Form:{
                    "id":'',
                    "name":'',
                    "pid":undefined,
                    "image":'',
                    "sort":'',
                    "code":'',
                    "is_show":undefined,
                    "bg_image":'',
                    "level":'',
                    "remark":''
                }
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '图标',
                    dataIndex: 'image',
                    key: 'image',
                },
                {
                    title: '分类编码',
                    dataIndex: 'code',
                    key: 'code',
                },
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '状态',
                    key: 'is_show',
                    dataIndex: 'is_show',
                },
                {
                    title: '层级',
                    key: 'level',
                    dataIndex: 'level',
                },
                {
                    title: '排序',
                    key: 'sort',
                    dataIndex: 'sort',
                },
                {
                    title: '备注',
                    key: 'remark',
                    dataIndex: 'remark',
                },
                {
                    title: '展示',
                    dataIndex: 'is_show',
                    key: 'is_show',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250
                },
            ];



            const gettype = async() => {
                state.loading = true
                const params = {}
                const res = await ApiUser.categoryList(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.typeData = data
                    if(state.typeData.length==0){
                        state.list = []
                        state.loading = false
                    }else{
                        state.activeKey = state.typeData[0].id
                        if(state.typeData[0].sub){
                            state.list =JSON.parse(JSON.stringify(state.typeData[0].sub).replace(/"sub"/g,"\"children\""))
                        }else{
                            state.list = []
                        }

                        state.loading = false
                        // getList()
                    }

                }
            }


            const callback = (key,index) => {
                state.activeKey = key
                console.log(state.typeData)
                state.list =[]
                state.typeData.forEach((item,index)=>{
                    if(item.id == key){
                        state.list = JSON.parse(JSON.stringify(item.sub).replace(/"sub"/g,"\"children\""))
                    }
                })
            }

            const onDel = async(val) =>{
                const params = {id: val.id}
                const res = await ApiUser.CategoryDel(params)
                if (res.error_code == 0) {
                   message.success(res.msg)
                    gettype()
                }else{
                    message.error(res.msg)
                }
            }

            const edit = (val) =>{
                state.fileList = []
                state.editForm.id = val.id
                state.editForm.pid = val.pid
                state.editForm.name = val.name
                state.editForm.image = val.image
                state.editForm.sort = val.sort
                state.editForm.code = val.code
                state.editForm.is_show = val.is_show
                state.editForm.bg_image = val.bg_image
                state.editForm.level = val.level
                state.editForm.remark = val.remark ? val.remark :''
                state.visible = true
            }
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.editForm.image = data.uri
                    }
                }
            }

            const handleChange2 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.Form.image = data.uri
                    }
                }
            }

            const handleOk = async() => {
                const res = await ApiUser.CategoryEdit(state.editForm)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    state.visible = false
                    gettype()
                }else{
                    message.error(res.msg)
                }
            }

            const handleOk2 = async() => {
                if(state.Form.pid == undefined){
                    state.Form.pid =0
                }
                const res = await ApiUser.CategoryAdd(state.Form)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    state.visible2 = false
                    gettype()
                }else{
                    message.error(res.msg)
                }
            }
            const addSon = (val) => {
                console.log(val)
                state.visible2 = true
                state.fileList = []
                state.type=2
                state.Form={
                    "id":val.id,
                    "name":'',
                    "pid":val.id,
                    "image":'',
                    "sort":'',
                    "code":'',
                    "is_show":undefined,
                    "bg_image":'',
                    "level":'',
                    "remark":''
                }
            }

            const addCate = () => {
                state.visible2 = true
                state.fileList = []
                state.type =1
                state.Form={
                    "id":'',
                    "name":'',
                    "pid":undefined,
                    "image":'',
                    "sort":'',
                    "code":'',
                    "is_show":undefined,
                    "bg_image":'',
                    "level":'',
                    "remark":''
                }

            }
            onMounted(() => {
                gettype()


            })

            return {
                ...toRefs(state),
                addCate,
                addSon,
                gettype,
                uploadHeaders,
                uploadFormData,
                handleChange2,
                handleOk,
                uploadAction,
                handleChange,
                handleOk2,
                edit,
                callback,
                columns,
                onDel
            }
        }
    });
